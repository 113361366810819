window.initialize = function initialize() {
  // Google Maps
  const latLng = new google.maps.LatLng(43.620318, 3.547661);
  const zoom = 12;
  const mapOptions = {
    center: latLng,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoom: zoom,
  };
  const map = new google.maps.Map(
    document.getElementById("map-canvas"),
    mapOptions
  );

  // Place
  const request = {
    placeId: "ChIJ0ThHkPhasRIR4ZH-KLfgZso",
  };

  const infowindow = new google.maps.InfoWindow();
  const service = new google.maps.places.PlacesService(map);

  service.getDetails(request, function (place, status) {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      const marker = new google.maps.Marker({
        map: map,
        position: place.geometry.location,
      });

      google.maps.event.addListener(marker, "click", function () {
        infowindow.setContent(
          '<div class="Gmaps"><h1 class="Person-name">' +
            place.name +
            "</h1>" +
            $("#Rendez-vous").html() +
            "</div>"
        );

        infowindow.open(map, this);
      });
    }
  });
};

function onload() {
  // Google maps
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src =
    "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=AIzaSyDhx7QY4Jw-A0-8_jzOake_zG0hPA_zK08&" +
    "callback=initialize";
  document.body.appendChild(script);

  // Menu
  const menu = Array.from(document.getElementsByClassName("navbar-toggle"));

  const collapsing = (t, c) => {
    const isCollasping = t.classList.contains("collapsing");
    if (isCollasping) return;

    const isIn = t.classList.contains("in");

    t.classList.remove("collapse");
    t.classList.remove("in");
    t.classList.add("collapsing");
    t.style.height = `${isIn ? 1 : c.offsetHeight + 16}px`;

    setTimeout(() => {
      t.classList.remove("collapsing");
      t.classList.add("collapse");
      isIn ? t.classList.remove("in") : t.classList.add("in");
    }, 400);
  };

  menu.forEach((m) => {
    const target = document.getElementById(m.getAttribute("data-target"));
    if (!target) return;
    target.style.height = "1px";
    const children = Array.from(
      target.getElementsByClassName("Navigation-bar")
    )[0];
    if (!children) return;

    m.addEventListener("click", (e) => collapsing(target, children));
  });
}

window.onload = onload;
